import React, { useState, useEffect } from "react";
import axios from "axios";

import Preloader from "components/Preloader";
import Header from "components/Header";
import Footer from "components/Footer";
import Hero from "components/Hero";
import Countdown from "components/Countdown";
import About from "components/About";
import Programma from "components/Programma";
import Counters from "components/Counters";
import Promotori from "components/Promotori";
import Video from "components/Video";
import Tickets from "components/Tickets";
import Speaker from "components/Speaker";
import Ortobello from "components/Ortobello";

import styles from "input.css";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

//import siteData from "data.json";

const Home = () => {
	const [siteData, setSiteData] = useState(null);

	useEffect(() => {
		const fetchData = async (section = null, event_id = null) => {
			let params = "";
			let url = "https://hortiaperti.com/api/?event_id=1";

			if (event_id) {
				params += "?event_id=" + event_id;
			}

			if (section) {
				if (event_id) {
					params += "&section=" + section;
				} else {
					params += "?section=" + section;
				}
			}

			url += params;
			const options = {
				method: "GET",
				headers: {
					"X-API-KEY": "4c897063-63e3-4c5e-a734-ee68a03f7c3b",
				},
			};

			fetch(url, options)
				.then((response) => {
					if (!response.ok) {
						throw new Error(
							"Network response was not ok " + response.statusText
						);
					}

					return response.json();
				})
				.then((data) => {
					setSiteData(data);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		};

		fetchData();
	}, []);

	// if (siteData) {
	// 	console.log(siteData.sponsors);
	// 	debugger;
	// }

	if (siteData)
		return (
			<>
				<div id="wrapper">
					{/* <Preloader /> */}

					{console.log(siteData)}

					<Header />

					<div className="main-content">
						<section id="home" className="divider">
							<div className="container-fluid p-0">
								<Hero
									start_date={siteData.event ? siteData.event.start_date : ""}
									end_date={siteData.event ? siteData.event.end_date : ""}
									year_date={siteData.event ? siteData.event.year_date : ""}
									location={siteData.event ? siteData.event.location : ""}
									ticket_url={siteData.event ? siteData.event.ticket_url : ""}
									expositors={
										siteData.expositors ? siteData.expositors.length : 0
									}
								/>

								<Countdown
									location={siteData.event ? siteData.event.location : ""}
									expositors={
										siteData.expositors ? siteData.expositors.length : 0
									}
								/>

								{/* <ScrollAnimation animateIn="fadeInLeft"> */}
								<About
									description={siteData.event ? siteData.event.description : ""}
									pdf_url={siteData.event ? siteData.event.pdf_url : ""}
								/>
								{/* </ScrollAnimation> */}

								{/* <ScrollAnimation animateIn="fadeInRight"> */}
								<Counters
									expositors={
										siteData.expositors ? siteData.expositors.length : 0
									}
									conferences={
										siteData.conferences ? siteData.conferences.length : 0
									}
									labs={siteData.labs ? siteData.labs : 0}
								/>
								{/* </ScrollAnimation> */}

								{/* <ScrollAnimation animateIn="fadeInLeft"> */}
								<Tickets
									ticket_url={siteData.event ? siteData.event.ticket_url : ""}
								/>
								{/* </ScrollAnimation> */}

								{/* <ScrollAnimation animateIn="fadeIn">
									<Programma
										programs={siteData.programs ? siteData.programs : null}
									/>
								</ScrollAnimation> */}

								<Speaker
									conferences={
										siteData.conferences ? siteData.conferences : null
									}
								/>

								{/* <ScrollAnimation animateIn="fadeInRight"> */}
								<Video />
								{/* </ScrollAnimation> */}

								<Ortobello
									coverUrl={
										siteData.contests ? siteData.contests.cover_url : null
									}
								/>

								{/* <ScrollAnimation animateIn="fadeIn"> */}
								<Promotori
									sponsors={siteData.sponsors ? siteData.sponsors : null}
								/>
								{/* </ScrollAnimation> */}
							</div>
						</section>
					</div>

					<Footer />
				</div>
			</>
		);
};

export default Home;
