import React from "react";
import { Link } from "react-router-dom";

import logo from "images/logo-small-white.png";
import logoBig from "images/logo-big.png";

import { contatti } from "site-structure";

const Footer = () => {
	return (
		<>
			<footer id="footer" className="footer">
				<div className="container pt-60 pb-30">
					<div className="row">
						<div className="col-md-6 col-md-offset-3 text-center">
							<Link to="/home">
								<img src={logo} alt="" style={{ width: 240 }} />
							</Link>
							<p className="font-16 mt-20 mb-20 text-white">
								Il festival del verde in città.
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12 col-md-6">
							<div className="widget dark">
								<h6 className="widget-title">Link utili</h6>
								<ul className="list-border list theme-colored angle-double-right text-white">
									<li>
										<Link className="text-white" to="/area-stampa">
											Area stampa
										</Link>
									</li>
									<li>
										<Link className="text-white" to="/termini-di-utilizzo">
											Termini di utilizzo
										</Link>
									</li>
									<li>
										<Link className="text-white" to="/privacy">
											Privacy Policy
										</Link>
									</li>
									<li>
										<Link className="text-white" to="/cookie">
											Cookie Policy
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-sm-12 col-md-6">
							<div className="flex items-center justify-center">
								<div
									style={{
										backgroundImage: `url(${logoBig})`,
										backgroundSize: "cover",
										width: 300,
										height: 220,
									}}
									alt="Horti aperti"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid p-20" data-bg-color="#181818">
					<div className="row text-center">
						<ul className="social-icons flat medium list-inline mb-40">
							<li>
								<a target="_blank" href="https://www.facebook.com/hortiaperti">
									<i className="fa fa-facebook text-white"></i>
								</a>{" "}
							</li>
							<li>
								<a
									target="_blank"
									href="https://www.instagram.com/hortiaperti/">
									<i className="fa fa-instagram text-white"></i>
								</a>{" "}
							</li>
						</ul>
						<div className="col-md-12">
							<p className="font-13 m-0 text-white">
								Copyright &copy;{new Date().getFullYear()}{" "}
								<a
									className="font-11"
									href="https://www.universpavia.it/"
									target="_blank"
									rel="noreferrer">
									Univers
								</a>
								. Tutti i diritti sono riservati.
							</p>
						</div>
					</div>
				</div>
			</footer>
			<a className="scrollToTop" href="#">
				<i className="fa fa-angle-up"></i>
			</a>
		</>
	);
};

export default Footer;
