import React from "react";
import { Link } from "react-router-dom";

import { promotori } from "site-structure";

import "./promotori.css";

import left from "images/flower_bg.png";
import right from "images/flower_bg.png";

const Promotori = ({ sponsors }) => {
	const promotoriImg = sponsors["promotori"];
	const coPromotori = sponsors["co-promotori"];
	const patrocinio = sponsors["patrocinio"];
	const contributo = sponsors["con-il-contributo-di"];
	const sostegno = sponsors["sostegno"];

	const renderPromotori = () =>
		promotoriImg.map((promotore, index) => {
			return (
				<>
					<div
						key={promotore + "_key"}
						className="col-xs-6 col-sm-6 col-md-3"
						style={{ border: "1px solid #f7f7f7", background: "#fff" }}>
						<div className="team-member">
							<div className="team-thumb">
								<div
									className="img-fullwidth"
									style={{
										backgroundImage: `url(${promotoriImg[index]})`,
										height: 160,
										backgroundSize: "100px",
										backgroundPosition: "50% 50%",
										backgroundRepeat: "no-repeat",
									}}
								/>
							</div>
						</div>
					</div>
				</>
			);

			return null;
		});

	const renderCoPromotori = () =>
		coPromotori.map((promotore, index) => {
			return (
				<>
					<div
						className="col-xs-6 col-sm-6 col-md-3"
						style={{ border: "1px solid #f7f7f7", background: "#fff" }}>
						<div className="team-member">
							<div className="team-thumb">
								<div
									className="img-fullwidth"
									style={{
										backgroundImage: `url(${coPromotori[index]})`,
										height: 160,
										backgroundSize: "100px",
										backgroundPosition: "50% 50%",
										backgroundRepeat: "no-repeat",
									}}
								/>
							</div>
						</div>
					</div>
				</>
			);

			return null;
		});

	const renderPatrocinio = () =>
		patrocinio.map((promotore, index) => {
			return (
				<>
					<div
						className="col-xs-6 col-sm-6 col-md-3"
						style={{ border: "1px solid #f7f7f7", background: "#fff" }}>
						<div className="team-member">
							<div className="team-thumb">
								<div
									className="img-fullwidth"
									style={{
										backgroundImage: `url(${patrocinio[index]})`,
										height: 160,
										backgroundSize: "100px",
										backgroundPosition: "50% 50%",
										backgroundRepeat: "no-repeat",
									}}
								/>
							</div>
						</div>
					</div>
				</>
			);

			return null;
		});

	const renderContributo = () =>
		contributo.map((promotore, index) => {
			return (
				<>
					<div
						className="col-xs-6 col-sm-6 col-md-3"
						style={{ border: "1px solid #f7f7f7", background: "#fff" }}>
						<div className="team-member">
							<div className="team-thumb">
								<div
									className="img-fullwidth"
									style={{
										backgroundImage: `url(${contributo[index]})`,
										height: 160,
										backgroundSize: "100px",
										backgroundPosition: "50% 50%",
										backgroundRepeat: "no-repeat",
									}}
								/>
							</div>
						</div>
					</div>
				</>
			);

			return null;
		});

	const renderSostegno = () =>
		sostegno.map((promotore, index) => {
			return (
				<>
					<div
						className="col-xs-6 col-sm-6 col-md-3"
						style={{ border: "1px solid #f7f7f7", background: "#fff" }}>
						<div className="team-member">
							<div className="team-thumb">
								<div
									className="img-fullwidth"
									style={{
										backgroundImage: `url(${sostegno[index]})`,
										height: 160,
										backgroundSize: "100px",
										backgroundPosition: "50% 50%",
										backgroundRepeat: "no-repeat",
									}}
								/>
							</div>
						</div>
					</div>
				</>
			);

			return null;
		});

	return (
		<section
			style={{
				// backgroundImage: `url(${bg}), linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .8))`,
				// backgroundRepeat: "no-repeat",
				position: "relative",
			}}>
			<div
				className="side-background-promotori left"
				style={{
					backgroundImage: `url(${left})`,
					backgroundPosition: "center right",
				}}></div>
			<section className="">
				<div className="container pt-40 pb-40">
					<div className="section-title">
						<div className="row">
							<div className="col-md-6 col-md-offset-3">
								<div className="text-center">
									{/* <h5 className="sub-title top-side-line">
										{promotori.heading}
									</h5> */}
									<h2 className="title">{promotori.title}</h2>
									<p>{promotori.subtitle}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="divider">
				<div className="container pb-50" style={{ paddingTop: 0 }}>
					<div className="section-content pl-20 pr-20">
						{promotoriImg !== undefined ? (
							<div className="row multi-row-clearfix">
								<div className="blog-posts">{renderPromotori()}</div>
							</div>
						) : (
							""
						)}

						{coPromotori !== undefined ? (
							<div className="row multi-row-clearfix">
								<div class="esc-heading heading-line-bottom lr-line left-heading">
									<h5>Co-Promotori</h5>
								</div>
								<div className="blog-posts">{renderCoPromotori()}</div>
							</div>
						) : (
							""
						)}

						{patrocinio !== undefined ? (
							<div className="row multi-row-clearfix">
								<div class="esc-heading heading-line-bottom lr-line left-heading">
									<h5>Con il patrocinio di</h5>
								</div>
								<div className="blog-posts">{renderPatrocinio()}</div>
							</div>
						) : (
							""
						)}

						{contributo !== undefined ? (
							<div className="row multi-row-clearfix">
								<div class="esc-heading heading-line-bottom lr-line left-heading">
									<h5>Con il contributo di</h5>
								</div>
								<div className="blog-posts">{renderContributo()}</div>
							</div>
						) : (
							""
						)}

						{sostegno !== undefined ? (
							<div className="row multi-row-clearfix">
								<div class="esc-heading heading-line-bottom lr-line left-heading">
									<h5>Con il sostegno di</h5>
								</div>
								<div className="blog-posts">{renderSostegno()}</div>
							</div>
						) : (
							""
						)}
					</div>
				</div>
			</div>
			<div
				className="side-background-promotori right"
				style={{
					backgroundImage: `url(${right})`,
					backgroundPosition: "center right",
				}}></div>
		</section>
	);
};

export default Promotori;
