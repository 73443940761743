import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
	return (
		<>
			<div className="main-content">
				<section id="home" className="fullscreen bg-lightest">
					<div className="display-table text-center">
						<div className="display-table-cell">
							<div className="container pt-0 pb-0">
								<div className="row">
									<div className="col-md-8 col-md-offset-2">
										<h1 className="font-150 text-theme-colored mt-0 mb-0">
											<i className="fa fa-map-signs text-gray-silver"></i>404!
										</h1>
										<h2 className="mt-0">Oops! Qualcosa è andato storto</h2>
										<p>Non troviamo la pagina che stai cercando.</p>
										<Link
											className="btn btn-border btn-gray btn-transparent btn-circled smooth-scroll-to-target"
											to="/home">
											Vai alla Home
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default Error;
