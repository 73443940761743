import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";

import bg from "images/cover-right.jpg";

import { programmaDettaglioPage } from "site-structure";

const DettaglioProgramma = () => {
	// const [siteData, setSiteData] = useState(null);
	// let { id } = useParams();

	// useEffect(() => {
	// 	const fetchData = async (section = null, event_id = null) => {
	// 		let params = "";
	// 		let url = "https://hortiaperti.com/api/?event_id=1&section=programs";

	// 		if (event_id) {
	// 			params += "?event_id=" + event_id;
	// 		}

	// 		if (section) {
	// 			if (event_id) {
	// 				params += "&section=" + section;
	// 			} else {
	// 				params += "?section=" + section;
	// 			}
	// 		}

	// 		url += params;
	// 		const options = {
	// 			method: "GET",
	// 			headers: {
	// 				"X-API-KEY": "4c897063-63e3-4c5e-a734-ee68a03f7c3b",
	// 			},
	// 		};

	// 		fetch(url, options)
	// 			.then((response) => {
	// 				if (!response.ok) {
	// 					throw new Error(
	// 						"Network response was not ok " + response.statusText
	// 					);
	// 				}

	// 				return response.json();
	// 			})
	// 			.then((data) => {
	// 				setSiteData(data);
	// 			})
	// 			.catch((error) => {
	// 				console.error("Error fetching data:", error);
	// 			});
	// 	};

	// 	fetchData();
	// }, []);

	// if (siteData) {
	// const program = siteData.programs.filter((oggetto) =>
	// 	oggetto.name.includes(id)
	// );

	return (
		<>
			<div id="wrapper">
				<Header />

				<div className="main-content">
					<section
						className="inner-header divider parallax layer-overlay overlay-dark-5"
						style={{
							backgroundImage: `url(${bg})`,
							backgroundPosition: "50%",
						}}>
						<div className="container pt-90 pb-30">
							<div className="section-content pt-100">
								<div className="row">
									<div className="col-md-12">
										<h3 className="title text-white">
											{programmaDettaglioPage.pageTitle}
										</h3>
									</div>
									<div className="col-md-12">
										<ol className="breadcrumb text-center mt-10 white">
											<li>
												<Link className="text-white" to="/home">
													Home
												</Link>
											</li>
											<li className="active">La manifestazione</li>
										</ol>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="bg-solid-color">
						<div className="container pb-80">
							<div className="row">
								<div className="col-md-12 mb-sm-30">
									<h2 className="title font-40 mt-0 mb-20">In dettaglio</h2>

									<p className="mb-20 text-large">
										Due giorni di incontri, laboratori e workshop risponderanno
										alle domande raccolte dal pubblico nelle edizioni precedenti
										e ai grandi quesiti del momento.
										<br />
										<br />A dare il via ad Horti Aperti Lab, come di consueto,
										la lectio magistralis del botanico e divulgatore Stefano
										Mancuso, direttore del LINV, Laboratorio di Neurobiologia
										Vegetale dell’Università di Firenze, che si terrà nell’agorà
										verde in cima al bosco, pronta ad accogliere fino a 200
										persone, nella mattinata di sabato 21 settembre.
										<br />
										<br />
										Mancuso risponderà alla domanda “Quanto sono eroiche le
										piante?”. Molti gli ospiti illustri. Il maestro giardiniere
										Carlo Pagani, fondatore del progetto Flora 2000 di Budrio
										(BO), affronterà un quesito caro a chi si avvicina al
										giardinaggio: “Un giardino può far da sé?”.
										<br />
										<br />
										Il meteorologo e fisico dell’atmosfera Mario Giuliacci
										interverrà sul tema “Il verde del Pianeta è a rischio?”
										L’interrogativo sembra scontato, le sue risposte non lo
										saranno affatto. Matteo Fiocco, in arte Matt the farmer,
										giovane fondatore dell’azienda agricola Stato Brado e
										youtuber seguitissimo, inviterà a cambiare stile di vita
										anche in giardino con una provocazione “Pronti per l’orto
										sostenibile?”.
										<br />
										<br />E a chi comincia adesso svelerà anche “Come fare
										l’orto in balcone?” Molti anche gli incontri tenuti da
										vivaisti, ma non solo. Si parlerà di rose, di piante grasse,
										di specie da laghetto, di convivenza tra galline da
										compagnia e giardino e di tanto altro ancora. Ci saranno
										workshop e minicorsi per i bambini.
									</p>
								</div>
								<div className="col-md-6">
									<div className="row">
										<div className="col-md-12 mb-10">
											<img
												className="img-fullwidth"
												src="images/about/4.jpg"
												alt=""
											/>
										</div>
										<div className="col-xs-6 col-md-6 pr-5">
											<img
												className="img-fullwidth"
												src="images/about/5.jpg"
												alt=""
											/>
										</div>
										<div className="col-xs-6 col-md-6 pl-5">
											<img
												className="img-fullwidth"
												src="images/about/6.jpg"
												alt=""
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>

				<Footer />
			</div>
		</>
	);
	// }
};

export default DettaglioProgramma;
