import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";

import "./espositori.css";

import bg from "images/cover-right.jpg";
import left from "images/flower_bg.png";
import right from "images/flower_bg.png";

import { espositoriPage } from "site-structure";

const Espositori = () => {
	const [siteData, setSiteData] = useState(null);

	const renderExpositors = () =>
		siteData.expositors.map((expositor) => {
			return (
				<>
					<div className="col-xs-12 col-sm-6 col-md-3">
						<div className="team-member">
							<div className="team-thumb">
								<div
									src={expositor.image_url}
									className="img-fullwidth"
									style={{
										backgroundImage: `url(${expositor.image_url})`,
										height: 260,
										backgroundSize: "cover",
										backgroundPosition: "center",
										borderRadius: "5px",
									}}
								/>
							</div>
							<div style={{ height: "100px" }}>
								<div class="title font-16 mt-20 mb-0">{expositor.name}</div>

								{expositor.description.length > 0 ? (
									<div
										class="sub-title font-14 text-gray-darkgray m-0 mt-8 mt-md-0"
										dangerouslySetInnerHTML={{
											__html: expositor.description,
										}}></div>
								) : (
									<div class="sub-title font-14 text-gray-darkgray m-0 mt-8 mt-md-0">
										&nbsp;
									</div>
								)}
							</div>
						</div>
					</div>
				</>
			);

			return null;
		});

	useEffect(() => {
		const fetchData = async (section = null, event_id = null) => {
			let params = "";
			let url = "https://hortiaperti.com/api/?event_id=1&section=expositors";

			if (event_id) {
				params += "?event_id=" + event_id;
			}

			if (section) {
				if (event_id) {
					params += "&section=" + section;
				} else {
					params += "?section=" + section;
				}
			}

			url += params;
			const options = {
				method: "GET",
				headers: {
					"X-API-KEY": "4c897063-63e3-4c5e-a734-ee68a03f7c3b",
				},
			};

			fetch(url, options)
				.then((response) => {
					if (!response.ok) {
						throw new Error(
							"Network response was not ok " + response.statusText
						);
					}

					return response.json();
				})
				.then((data) => {
					setSiteData(data);

					console.log(data);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		};

		fetchData();
	}, []);

	if (siteData)
		return (
			<>
				<div id="wrapper">
					<Header />

					<div className="main-content">
						<section
							className="inner-header divider parallax layer-overlay overlay-dark-5"
							style={{
								backgroundImage: `url(${bg})`,
								backgroundPosition: "0% bottom",
							}}>
							<div className="container pt-40 pb-30">
								<div className="section-content pt-100">
									<div className="row">
										<div className="col-md-12">
											<h3 className="title text-white">
												{espositoriPage.pageTitle}
											</h3>
										</div>
										<div className="col-md-12">
											<ol className="breadcrumb text-center mt-10 white">
												<li>
													<Link className="text-white" to="/home">
														Home
													</Link>
												</li>
												<li className="active">Espositori</li>
											</ol>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section>
							<div
								className="side-background-espositori left"
								style={{
									backgroundImage: `url(${left})`,
									backgroundPosition: "center right",
								}}></div>
							<div className="container pt-40 pb-50">
								<div className="section-content">
									<div className="row multi-row-clearfix">
										{renderExpositors()}
									</div>
								</div>
							</div>
							<div
								className="side-background-espositori right"
								style={{
									backgroundImage: `url(${right})`,
									backgroundPosition: "center right",
								}}></div>
						</section>

						<section class="bg-white-f7">
							<div class="container pb-60">
								<div class="section-title">
									<div class="row">
										<div class="col-md-6 col-md-offset-3">
											<div class="text-center">
												<h5 class="sub-title">Collaboriamo</h5>
												<h2 class="title">Sei un espositore?</h2>
												<p>
													Abbiamo invitato alla mostra mercato di Horti Aperti i
													migliori vivaisti italiani, selezionandoli per
													ricchezza, varietà e originalità delle loro
													collezioni. Per le varie tipologie di piante, il
													pubblico potrà trovare stimoli e curiosità impreviste.
													Tutti i vivaisti sono invitati a portare in mostra
													piante e fiori per creare una sorta di “giardino delle
													meraviglie” approfittando di un periodo - come il mese
													di settembre - nel quale la natura si appresta al
													riposo autunnale ma che dona ancora interessanti e
													preziose fioriture. Se pensi di avere qualcosa che può
													arricchire la nostra esposizione...
													<br />
													<br />
													<br />
													<a
														href="mailto:hortiaperti@hortiaperti.it"
														class="btn btn-dark btn-theme-colored btn-xl">
														Scrivici!
													</a>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>

					<Footer />
				</div>
			</>
		);
};

export default Espositori;
