import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";

import styles from "input.css";
import "./laboratori.css";

import bg from "images/cover-right.jpg";
import left from "images/flower_bg.png";
import right from "images/flower_bg.png";

const DettaglioLaboratorio = () => {
	const [siteData, setSiteData] = useState(null);
	let { id } = useParams();
	let { labData } = useParams();

	useEffect(() => {
		const fetchData = async (section = null, event_id = null) => {
			let params = "";
			let url = "https://hortiaperti.com/api/?event_id=1&section=labs";

			if (event_id) {
				params += "?event_id=" + event_id;
			}

			if (section) {
				if (event_id) {
					params += "&section=" + section;
				} else {
					params += "?section=" + section;
				}
			}

			url += params;
			const options = {
				method: "GET",
				headers: {
					"X-API-KEY": "4c897063-63e3-4c5e-a734-ee68a03f7c3b",
				},
			};

			fetch(url, options)
				.then((response) => {
					if (!response.ok) {
						throw new Error(
							"Network response was not ok " + response.statusText
						);
					}

					return response.json();
				})
				.then((data) => {
					setSiteData(data);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		};

		fetchData();
	}, []);

	if (siteData) {
		const conference = siteData.labs[labData].filter((oggetto) =>
			oggetto.title.includes(id.replace(/\--/g, "?"))
		);

		return (
			<>
				<div id="wrapper">
					<Header />

					<div className="main-content">
						<section
							className="inner-header divider parallax layer-overlay overlay-dark-5"
							style={{
								backgroundImage: `url(${bg})`,
								backgroundPosition: "50%",
							}}>
							<div className="container pt-40 pb-30">
								<div className="section-content pt-100">
									<div className="row">
										<div className="col-md-12">
											<h3 className="title text-white">
												{id.replace(/\--/g, "?")}
											</h3>
										</div>
										<div className="col-md-12">
											<ol className="breadcrumb text-center mt-10 white">
												<li>
													<Link className="text-white" to="/home">
														Home
													</Link>
												</li>
												<li className="active">
													<Link className="text-white" to="/laboratori">
														Laboratori
													</Link>
												</li>
											</ol>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section className="bg-solid-color">
							<div
								className="side-background-laboratori left"
								style={{
									backgroundImage: `url(${left})`,
									backgroundPosition: "center right",
								}}></div>
							<div
								className="container pt-40 pb-80"
								style={{ background: "#fff" }}>
								<div className="row">
									<div className="col-md-6 mb-sm-30">
										<h3 className="sub-title font-28 m-0 mt-0 mt-md-0">
											{conference[0].subtitle}
										</h3>
										<br />
										<p
											className="mb-20"
											dangerouslySetInnerHTML={{
												__html: conference[0].description,
											}}></p>
										<div class="esc-heading heading-line-bottom lr-line left-heading">
											<h5>Quando</h5>
										</div>
										<p>
											{conference[0].day} alle {conference[0].hour}
										</p>
										<div class="esc-heading heading-line-bottom lr-line left-heading">
											<h5>Dove</h5>
										</div>
										<p>{conference[0].place}</p>
										{conference[0].max_num_participants.length > 0 ? (
											<>
												<div class="esc-heading heading-line-bottom lr-line left-heading">
													<h5>Capienza</h5>
												</div>
												<p>{conference[0].max_num_participants}</p>
											</>
										) : (
											""
										)}
										<a
											target="_blank"
											rel="noreferrer"
											href={conference[0].link_to_buy}
											class="btn btn-dark btn-theme-colored btn-l mt-40">
											{conference[0].reservation === "1"
												? "Acquista biglietto"
												: "Prenota il posto"}
										</a>
									</div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-12 mb-10">
												<img
													className="img-fullwidth"
													style={{ borderRadius: "5px" }}
													src={conference[0].cover_url}
													alt=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								className="side-background-laboratori right"
								style={{
									backgroundImage: `url(${right})`,
									backgroundPosition: "center right",
								}}></div>
						</section>
					</div>

					<Footer />
				</div>
			</>
		);
	}
};

export default DettaglioLaboratorio;
