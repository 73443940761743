import Home from "pages/Home";
import Programma from "pages/Programma";
import DettaglioProgramma from "pages/DettaglioProgramma";
import DettaglioConferenza from "pages/DettaglioConferenza";
import DettaglioLaboratorio from "pages/DettaglioLaboratorio";
import Conferenze from "pages/Conferenze";
import Laboratori from "pages/Laboratori";
import Espositori from "pages/Espositori";
import Gallery from "pages/Gallery";
import Arrivare from "pages/Arrivare";
import Termini from "pages/Termini";
import Cookie from "pages/Cookie";
import Privacy from "pages/Privacy";
import AreaStampa from "pages/AreaStampa";
import Error from "pages/Error";

import {
	home,
	programmaPage,
	programmaDettaglioPage,
	conferenzePage,
	conferenzeDettaglioPage,
	laboratoriPage,
	laboratoriDettaglioPage,
	espositoriPage,
	arrivarePage,
	terminiPage,
	cookiePage,
	privacyPage,
	galleryPage,
	areaStampaPage,
	errorPage,
} from "site-structure.js";

const appname = process.env.REACT_APP_WEBSITE_NAME;

const routes = [
	{
		key: "home",
		...home,
		component: <Home title={`${home.pageTitle} - ${appname}`} />,
		visible: true,
	},
	{
		key: "programma",
		...programmaPage,
		component: <Programma title={`${programmaPage.pageTitle} - ${appname}`} />,
		visible: true,
	},
	{
		key: "programma-dettaglio",
		...programmaDettaglioPage,
		component: (
			<DettaglioProgramma
				title={`${programmaDettaglioPage.pageTitle} - ${appname}`}
			/>
		),
		visible: true,
	},
	{
		key: "conferenze",
		...conferenzePage,
		component: (
			<Conferenze title={`${conferenzePage.pageTitle} - ${appname}`} />
		),
		visible: true,
	},
	{
		key: "conferenze-dettaglio",
		...conferenzeDettaglioPage,
		component: (
			<DettaglioConferenza
				title={`${conferenzeDettaglioPage.pageTitle} - ${appname}`}
			/>
		),
		visible: true,
	},
	{
		key: "laboratori",
		...laboratoriPage,
		component: (
			<Laboratori title={`${laboratoriPage.pageTitle} - ${appname}`} />
		),
		visible: true,
	},
	{
		key: "laboratori-dettaglio",
		...laboratoriDettaglioPage,
		component: (
			<DettaglioLaboratorio
				title={`${laboratoriDettaglioPage.pageTitle} - ${appname}`}
			/>
		),
		visible: true,
	},
	{
		key: "espositori",
		...espositoriPage,
		component: (
			<Espositori title={`${espositoriPage.pageTitle} - ${appname}`} />
		),
		visible: true,
	},
	{
		key: "gallery",
		...galleryPage,
		component: <Gallery title={`${galleryPage.pageTitle} - ${appname}`} />,
		visible: true,
	},
	{
		key: "come-arrivare",
		...arrivarePage,
		component: <Arrivare title={`${arrivarePage.pageTitle} - ${appname}`} />,
		visible: true,
	},
	{
		key: "termini-di-utilizzo",
		...terminiPage,
		component: <Termini title={`${terminiPage.pageTitle} - ${appname}`} />,
		visible: true,
	},
	{
		key: "cookie",
		...cookiePage,
		component: <Cookie title={`${terminiPage.pageTitle} - ${appname}`} />,
		visible: true,
	},
	{
		key: "privacy",
		...privacyPage,
		component: <Privacy title={`${terminiPage.pageTitle} - ${appname}`} />,
		visible: true,
	},
	{
		key: "area-stampa",
		...areaStampaPage,
		component: (
			<AreaStampa title={`${areaStampaPage.pageTitle} - ${appname}`} />
		),
		visible: true,
	},
	{
		key: "errore",
		...errorPage,
		component: <Error title={`${errorPage.pageTitle} - ${appname}`} />,
		visible: true,
	},
];

export { routes };
