import React from "react";
import { Link } from "react-router-dom";

import "./ortobello.css";

import left from "images/flower_bg.png";
import right from "images/flower_bg.png";
import ortobello from "images/ortobello.jpeg";

import pdf from "pdf/iscrizione_ortobello.pdf";

const Ortobello = ({ coverUrl }) => {
	return (
		<section
			style={{
				position: "relative",
				overflow: "visible",
				backgroundColor: "#fef9ec",
			}}>
			{/* <div
				className="side-background left"
				style={{
					backgroundImage: `url(${left})`,
					backgroundPosition: "center right",
				}}></div> */}
			<div className="container pt-0 pt-40">
				<div className="section-title">
					{/* <div className="col-md-6 col-md-offset-3 mb-20">
						<div className="text-center">
							<h2 className="title">Ortobello</h2>
						</div>
					</div> */}
				</div>

				<div className="col-md-12 centered-content">
					<div className="row">
						<div className="col-md-12 text-center">
							<img style={{ borderRadius: "5px" }} src={ortobello} alt="" />
						</div>
						{/* <div className="col-md-6 mb-sm-30">
							<p className="mb-20 text-ortobello">
								Il concorso per il <b>Miglior Orto</b> amatoriale nel Comune di
								Pavia!
							</p>
						</div> */}
					</div>
					<div className="row text-center">
						<a
							href={pdf}
							className="btn btn-colored btn-lg text-uppercase smooth-scroll font-13 mt-30"
							style={{ backgroundColor: "#138773", color: "#fff" }}
							target="_blank"
							rel="noopener noreferrer">
							Unisciti a noi!
						</a>
					</div>
				</div>
			</div>
			{/* <div
				className="side-background right"
				style={{
					backgroundImage: `url(${right})`,
					backgroundSize: "cover",
					backgroundPosition: "center right",
				}}></div> */}
		</section>
	);
};

export default Ortobello;
