import React from "react";

import video1 from "video/video2.mp4";
import video2 from "video/video.mp4";

import bgFlowers from "images/bg-labs.jpg";

import "./video.css";

import video1poster from "images/video_cover_1.png";
import video2poster from "images/video_cover_2.png";

const Video = () => {
	return (
		<section
			className="divider parallax mt-sm-40"
			style={{
				// backgroundImage: `url(${bgFlowers})`,
				backgroundPosition: "0% bottom",
			}}>
			<div className="container pt-40 pb-0 pt-sm-0">
				<div className="section-title">
					<div className="row">
						<div className="col-md-6 col-md-offset-3">
							<div className="text-center">
								<h5 className="sub-title top-side-line">Edizioni passate</h5>
								<h2 className="title">Video</h2>
								<p>Scopri come Horti Aperti è cresciuto nel tempo</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="divider">
				<div className="container pb-50" style={{ paddingTop: 0 }}>
					<div className="section-content">
						<div className="row">
							<div className="col-xs-12 col-sm-6 col-md-6">
								<div class="esc-heading heading-line-bottom lr-line left-heading">
									<h5>Edizione 2022</h5>
								</div>
								<div class="fluid-video-wrapper">
									{/* <iframe
										src={video1}
										width="640"
										height="360"
										title="Video edizione 2022"
										allowfullscreen></iframe> */}
									<video src={video1} poster={video1poster} controls></video>
								</div>
							</div>
							<div className="col-xs-12 col-sm-6 col-md-6">
								<div class="esc-heading heading-line-bottom lr-line left-heading">
									<h5>Edizione 2023</h5>
								</div>
								<div class="fluid-video-wrapper">
									{/* <iframe
										src={video2}
										width="640"
										height="360"
										title="Video edizione 2023"
										allowfullscreen></iframe> */}
									<video src={video2} poster={video2poster} controls></video>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Video;
