import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import { routes } from "routes.js";

let location;

const App = () => {
	location = useLocation();

	//setto scroll pagina a 0 quando viene cambiata route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [location]);

	const getRoutes = (routes) =>
		routes.map((route) => {
			if ("collapse" in route) {
				getRoutes(route.collapse);
			}

			if (route.route != undefined)
				return (
					<Route
						exact
						path={route.route}
						element={route.component}
						key={route.key}></Route>
				);
			else return;
		});

	return (
		<>
			<Routes>
				<Route path="*" element={<Navigate to="/error" />} />

				<Route path="/" element={<Navigate to="/home" />} />

				{getRoutes(routes)}
			</Routes>
		</>
	);
};

export default App;
