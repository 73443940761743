// import fingerfood from "images/fingerfood.jpg";
// import homecooking from "images/homecooking.jpg";

const home = {
	pageTitle: "Home",
	description: "Home",
	section: "Home",
	linkName: "Home",
	route: "/home",
};

const hero = {
	location: "Pavia, 21 - 22 Settembre 2024",
	title: "Horti Aperti",
	subtitle: "Festival del verde in città",
	callToAction1: "Acquista i biglietti",
	action1: "https://shop.midaticket.it/Shops",
	callToAction2: "Guarda il programma",
	action2: "/programma",
};

const about = {
	title: "La manifestazione",
};

const programma = {
	heading: "Panoramica",
	title: "Il programma",
	subtitle:
		"Incontri, seminari, esposizioni, mostre, ospiti di rilievo. Scopri il programma della manifestazione",
};

const promotori = {
	heading: "Sponsor",
	title: "I promotori",
	subtitle: "Scopri chi contribuisce alla realizzazione di Horti Aperti",
};

const programmaPage = {
	pageTitle: "Programma",
	description: "Programma",
	section: "Programma",
	linkName: "Programma",
	route: "/programma",
};

const conferenzePage = {
	pageTitle: "Conferenze",
	description: "Conferenze",
	section: "Conferenze",
	linkName: "Conferenze",
	route: "/conferenze",
};

const conferenzeDettaglioPage = {
	pageTitle: "Conferenze",
	description: "Conferenze",
	section: "Conferenze",
	linkName: "Conferenze",
	route: "/dettaglio-conferenza/:id/confData/:confData",
};

const laboratoriPage = {
	pageTitle: "Laboratori",
	description: "Laboratori",
	section: "Laboratori",
	linkName: "Laboratori",
	route: "/laboratori",
};

const laboratoriDettaglioPage = {
	pageTitle: "Laboratori",
	description: "Laboratori",
	section: "Laboratori",
	linkName: "Laboratori",
	route: "/dettaglio-laboratorio/:id/labData/:labData",
};

const galleryPage = {
	pageTitle: "Galleria immagini",
	description: "Galleria immagini",
	section: "Galleria immagini",
	linkName: "Galleria immagini",
	route: "/gallery",
};

const programmaDettaglioPage = {
	pageTitle: "La manifestazione",
	description: "La manifestazione",
	section: "La manifestazione",
	linkName: "La manifestazione",
	route: "/dettaglio-programma/",
};

const espositoriPage = {
	pageTitle: "Espositori",
	description: "Espositori",
	section: "Espositori",
	linkName: "Espositori",
	route: "/espositori",
};

const arrivarePage = {
	pageTitle: "Come arrivare",
	description: "Come arrivare",
	section: "Come arrivare",
	linkName: "Come arrivare",
	route: "/come-arrivare",
};

const terminiPage = {
	pageTitle: "Termini di utilizzo",
	description: "Termini di utilizzo",
	section: "Termini di utilizzo",
	linkName: "Termini di utilizzo",
	route: "/termini-di-utilizzo",
};

const privacyPage = {
	pageTitle: "Privacy policy",
	description: "Privacy policy",
	section: "Privacy policy",
	linkName: "Privacy policy",
	route: "/privacy",
};

const cookiePage = {
	pageTitle: "Cookie policy",
	description: "Cookie policy",
	section: "Cookie policy",
	linkName: "Cookie policy",
	route: "/cookie",
};

const areaStampaPage = {
	pageTitle: "Area stampa",
	description: "Area stampa",
	section: "Area stampa",
	linkName: "Area stampa",
	route: "/area-stampa",
};

const contatti = {
	email: "hortiaperti@hortiaperti.it",
	phone: "0382539433",
	specifics: "Horti Aperti è il festival del verde in città.",
};

const errorPage = {
	pageTitle: "Errore",
	description: "Errore",
	section: "Errore",
	linkName: "Errore",
	route: "/error",
};

export {
	home,
	hero,
	about,
	programma,
	programmaPage,
	programmaDettaglioPage,
	promotori,
	conferenzePage,
	conferenzeDettaglioPage,
	laboratoriPage,
	laboratoriDettaglioPage,
	galleryPage,
	espositoriPage,
	arrivarePage,
	terminiPage,
	privacyPage,
	cookiePage,
	areaStampaPage,
	contatti,
	errorPage,
};
