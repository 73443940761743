import React from "react";

import "./tickets.css";

import bgFlowers from "images/bg-labs.jpg";

import left from "images/flower_bg.png";
import right from "images/flower_bg.png";

const Tickets = ({ ticket_url }) => {
	return (
		<section
			id="tickets"
			className="divider parallax"
			style={{
				// backgroundImage: `url(${bgFlowers})`,
				// backgroundPosition: "0% bottom",
				overflow: "visible",
			}}>
			<div
				className="side-background-tickets left"
				style={{
					backgroundImage: `url(${left})`,
					backgroundPosition: "center right",
				}}></div>
			<div className="container pb-50 pt-sm-0">
				<div className="section-title mb-30">
					<div className="col-md-6 col-md-offset-3 mb-30">
						<div className="text-center">
							<h5 className="sub-title top-side-line">Come accedere</h5>
							<h2 className="title">Biglietti</h2>
						</div>
					</div>
				</div>
				<div className="section-content">
					<div className="row equal-height-pricing-table">
						<div className="col-xs-12 col-sm-12 col-md-12 mb-30">
							<div className="pricing-table table-horizontal maxwidth400">
								<div className="row">
									<div className="col-sm-3">
										<div className="table-price text-white">€8</div>
										<h6 className="table-title text-white">Online</h6>
									</div>
									<div className="col-sm-3 mb-sm-40">
										<p>
											<ul style={{ listStyleType: "circle" }}>
												<li className="mb-10 text-white">
													Intero: €8,00 + prev €1,50
												</li>
												<li className="mb-10 text-white">
													2 giorni: €10,00 + prev €1,50
												</li>
												<li className="mb-10 text-white">
													tariffa famiglia (3 / 4 persone): €18,00 + prev 3€
												</li>
												<li className="mb-10 text-white">
													tariffa famiglia (3 / 4 persone) 2 giorni: €22,00 +
													prev 3€
												</li>
												<li className="mb-10 text-white">
													ridotto: €5,00 + prev €1.50
													<br />
													valido per: universitari dotati di tesserino da
													presentare all'ingresso, possessori di abbonamenti
													musei da esibire all'ingresso, iscritti FAI, ragazzi
													dai 15 ai 18 anni
												</li>
												<li className="mb-10 text-white">
													ridotto 2 giornate: €8,00 + prev €1.50
													<br />
													valido per: universitari dotati di tesserino da
													presentare all'ingresso, possessori di abbonamenti
													musei da esibire all'ingresso, iscritti FAI, ragazzi
													dai 15 ai 18 anni
												</li>
											</ul>
										</p>
										<a
											className="btn btn-default btn-lg mt-15 pl-20 pr-20"
											target="_blank"
											rel="noreferrer"
											href={ticket_url}>
											Acquista
										</a>
									</div>
									<div className="col-sm-3">
										<div className="table-price text-white">€8</div>
										<h6 className="table-title text-white">In cassa</h6>
									</div>
									<div className="col-sm-3">
										<p>
											<ul style={{ listStyleType: "circle" }}>
												<li className="mb-10 text-white">Intero: €8,00</li>
												<li className="mb-10 text-white">2 giorni: €10,00</li>
												<li className="mb-10 text-white">
													tariffa famiglia (3 / 4 persone): €17,00
												</li>
												<li className="mb-10 text-white">
													tariffa famiglia (3 / 4 persone) 2 giorni: €18,00
												</li>
												<li className="mb-10 text-white">
													ridotto: €5,00
													<br />
													valido per: universitari dotati di tesserino da
													presentare all'ingresso, possessori di abbonamenti
													musei da esibire all'ingresso, iscritti FAI, ragazzi
													dai 15 ai 18 anni
												</li>
												<li className="mb-10 text-white">
													ridotto 2 giornate: €8,00
													<br />
													valido per: universitari dotati di tesserino da
													presentare all'ingresso, possessori di abbonamenti
													musei da esibire all'ingresso, iscritti FAI, ragazzi
													dai 15 ai 18 anni
												</li>
											</ul>
										</p>
										<a
											className="btn btn-gray btn-theme-colored btn-lg mt-15 pl-20 pr-20"
											style={{ visibility: "hidden" }}>
											Acquista
										</a>
									</div>
								</div>
							</div>
						</div>
						{/* <div className="col-xs-12 col-sm-6 col-md-6 mb-30">
							<div className="pricing-table table-horizontal maxwidth400">
								<div className="row">
									<div className="col-sm-6">
										<div className="table-price text-white">€8</div>
										<h6 className="table-title text-white">In cassa</h6>
									</div>
									<div className="col-sm-6">
										<p style={{ fontFamily: "Rothorn DC" }}>
											<ul style={{ listStyleType: "circle" }}>
												<li className="mb-10 text-white">Intero: €8,00</li>
												<li className="mb-10 text-white">2 giorni: €10,00</li>
												<li className="mb-10 text-white">
													tariffa famiglia (3 / 4 persone): €17,00
												</li>
												<li className="mb-10 text-white">
													tariffa famiglia (3 / 4 persone) 2 giorni: €18,00
												</li>
											</ul>
										</p>
										<a
											className="btn btn-gray btn-theme-colored btn-lg mt-15 pl-20 pr-20"
											style={{ visibility: "hidden" }}>
											Acquista
										</a>
									</div>
								</div>
							</div>
						</div> */}

						{/* <div className="col-md-8 col-md-offset-2">
							<div className="col-xs-12 col-sm-6 col-md-6 p-0">
								<div className="pricing-table maxwidth400">
									<div className="table-price text-white">€8</div>
									<h6 className="table-title text-theme-colored">Online</h6>
									<p>
										<ul style={{ listStyleType: "circle" }}>
											<li className="mb-10 text-white">
												Intero: €8,00 + prev €1,50
											</li>
											<li className="mb-10 text-white">
												2 giorni: €10,00 + prev €1,50
											</li>
											<li className="mb-10 text-white">
												tariffa famiglia (3 / 4 persone): €18,00
											</li>
											<li className="mb-10 text-white">
												tariffa famiglia (3 / 4 persone) 2 giorni: €22,00
											</li>
										</ul>
									</p>
									<a
										className="btn btn-colored btn-theme-colored btn-sm mt-15 pl-20 pr-20"
										target="_blank"
										rel="noreferrer"
										href={ticket_url}>
										Acquista
									</a>
								</div>
							</div>
							<div className="col-xs-12 col-sm-6 col-md-6 p-0">
								<div className="pricing-table maxwidth400">
									<div className="table-price text-white">€8</div>
									<h6 className="table-title text-theme-colored">In cassa</h6>
									<p>
										<ul style={{ listStyleType: "circle" }}>
											<li className="mb-10 text-white">
												Intero: €8,00 + prev €1,50
											</li>
											<li className="mb-10 text-white">
												2 giorni: €10,00 + prev €1,50
											</li>
											<li className="mb-10 text-white">
												tariffa famiglia (3 / 4 persone): €18,00
											</li>
											<li className="mb-10 text-white">
												tariffa famiglia (3 / 4 persone) 2 giorni: €22,00
											</li>
										</ul>
									</p>
									<a
										className="btn btn-colored btn-theme-colored btn-sm pl-20 pr-20 mt-30"
										style={{ visibility: "hidden" }}>
										Buy Ticket
									</a>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>
			<div
				className="side-background-tickets right"
				style={{
					backgroundImage: `url(${right})`,
					backgroundSize: "cover",
					backgroundPosition: "center right",
				}}></div>
		</section>
	);
};

export default Tickets;
