import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import bg1 from "images/bg-1.jpg";
import bg2 from "images/bg-2.jpg";
import bg3 from "images/bg-3.jpg";
import bg4 from "images/bg-4.jpg";
import logo from "images/logo-big.png";

import { hero } from "site-structure";

const Hero = ({
	start_date,
	end_date,
	year_date,
	location,
	ticket_url,
	expositors,
}) => {
	const owlCarouselRef = useRef(null);

	useEffect(() => {
		// Aggiungi un listener per l'evento popstate
		const handlePopstate = () => {
			// Riavvia Owl Carousel quando viene scatenato l'evento popstate
			if (owlCarouselRef.current) {
				owlCarouselRef.current.onInitialize();
			}
		};

		window.addEventListener("popstate", handlePopstate);

		// Cleanup: rimuovi il listener quando il componente viene smontato
		return () => {
			window.removeEventListener("popstate", handlePopstate);
		};
	}, []);

	return (
		<>
			<div className="rev_slider_wrapper" style={{ height: "100vh" }}>
				<div className="rev_slider">
					<ul>
						<OwlCarousel
							ref={owlCarouselRef}
							className="owl-theme"
							loop
							nav
							items={1}
							autoplay
							autoplayTimeout={10000}>
							<li className="item">
								<div
									style={{
										height: "100vh",
										backgroundImage: `url(${bg1})`,
										backgroundSize: "cover",
										backgroundPosition: "center",
									}}>
									<div
										className="overlay"
										style={{
											position: "absolute",
											top: 0,
											left: 0,
											width: "100%",
											height: "100%",
											background:
												"linear-gradient(180deg, rgba(0,0,0,.52) 0%, rgba(0,0,0,.52) 60%)",
											zIndex: 0,
										}}></div>
									<div
										className="relative px-6"
										style={{
											height: "80vh",
											display: "table",
											tableLayout: "fixed",
											width: "100%",
										}}>
										<div
											className=""
											style={{
												height: "80vh",
												display: "table-cell",
												width: "100%",
												verticalAlign: "middle",
												textAlign: "center",
											}}>
											<div className="col-md-12 text-center p-40">
												<p
													style={{
														fontFamily: "Open Sans",
														fontSize: "30px",
														color: "#fff",
													}}>
													III Edizione
												</p>
												<p className="text-white font-64">
													<strong>Il festival del verde in città</strong>
												</p>
												<p
													className="text-white font-24"
													style={{
														fontFamily: "Open Sans",
														fontSize: "24px",
														fontWeight: "600",
													}}>
													21-22 settembre 2024, Horti del Collegio Borromeo,
													Pavia
												</p>
											</div>
										</div>
									</div>
									{/* <div>
										<div className="relative px-6 pt-14 lg:px-8">
											<div className="mx-auto max-w-2xl mt-100 mt-sm-40">
												<div
													className="col-md-12 text-center p-40"
													style={{
														backgroundColor: "rgb(43 74 154 / 82%)",
														borderRadius: "5px",
														backdropFilter: "blur(8px)",
													}}>
													<div
														className="mb-40"
														style={{
															backgroundImage: `url(${logo})`,
															backgroundSize: "cover",
															width: 220,
															height: 180,
															margin: "0 auto",
														}}
														alt="Horti aperti"></div>

													<p className="text-white font-24">
														dal <strong>{start_date ? start_date : ""}</strong>{" "}
														al <strong>{end_date ? end_date : ""}</strong>{" "}
														{year_date ? year_date : ""}
														<br />
														Horti del Collegio Borromeo
													</p>
													<a
														className="btn btn-gray btn-theme-colored btn-lg smooth-scroll font-18 mt-40"
														target="_blank"
														rel="noreferrer"
														href={ticket_url}>
														Acquista i biglietti
													</a>
												</div>
												<div className="text-center">
													<video
														autoPlay
														loop
														muted
														height={"80vh"}
														width={"100%"}
														style={{
															position: "fixed",
															height: "100%",
															objectFit: "cover",
															zIndex: -1,
															left: 0,
															right: 0,
															top: 0,
															left: 0,
														}}>
														<source src={"test"} type="video/mp4" />
													</video>
													<div className="flex items-center justify-center">
														<div
															style={{
																backgroundImage: `url(${logo})`,
																backgroundSize: "cover",
																width: 300,
																height: 220,
															}}
															alt="Horti aperti"></div>
													</div>
													<div className="py-12 flex items-center justify-center gap-x-6">
														<a
															target="_blank"
															href={ticket_url}
															className="rounded-md bg-hortiaperti-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-hortiaperti-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
															{hero.callToAction1}
														</a>
													</div>
												</div>
											</div>
											<div
												className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
												aria-hidden="true">
												<div
													className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
													sx="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
											</div>
										</div>
									</div> */}
								</div>
							</li>
							<li className="item">
								<div
									style={{
										height: "100vh",
										backgroundImage: `url(${bg2})`,
										backgroundSize: "cover",
										backgroundPosition: "center",
									}}>
									<div
										className="overlay"
										style={{
											position: "absolute",
											top: 0,
											left: 0,
											width: "100%",
											height: "100%",
											background:
												"linear-gradient(180deg, rgba(0,0,0,.52) 0%, rgba(0,0,0,.52) 60%)",
											zIndex: 0,
										}}></div>
									<div
										className="relative px-6"
										style={{
											height: "80vh",
											display: "table",
											tableLayout: "fixed",
											width: "100%",
										}}>
										<div
											style={{
												height: "80vh",
												display: "table-cell",
												width: "100%",
												verticalAlign: "middle",
												textAlign: "center",
											}}>
											<div className="col-md-12 text-center p-40">
												<p
													style={{
														fontFamily: "Open Sans",
														fontSize: "30px",
														color: "#fff",
													}}>
													III Edizione
												</p>
												<p className="text-white font-64">
													<strong>Il bosco e il giardino</strong>
												</p>
												<p
													className="text-white font-24"
													style={{
														fontFamily: "Open Sans",
														fontWeight: "600",
													}}>
													21-22 settembre 2024, Horti del Collegio Borromeo,
													Pavia
												</p>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li className="item">
								<div
									style={{
										height: "100vh",
										backgroundImage: `url(${bg3})`,
										backgroundSize: "cover",
										backgroundPosition: "center",
									}}>
									<div
										className="overlay"
										style={{
											position: "absolute",
											top: 0,
											left: 0,
											width: "100%",
											height: "100%",
											background:
												"linear-gradient(180deg, rgba(0,0,0,.52) 0%, rgba(0,0,0,.52) 60%)",
											zIndex: 0,
										}}></div>
									<div
										className="relative px-6"
										style={{
											height: "80vh",
											display: "table",
											tableLayout: "fixed",
											width: "100%",
										}}>
										<div
											style={{
												height: "80vh",
												display: "table-cell",
												width: "100%",
												verticalAlign: "middle",
												textAlign: "center",
											}}>
											<div className="col-md-12 text-center p-40">
												<p
													style={{
														fontFamily: "Open Sans",
														fontSize: "30px",
														color: "#fff",
													}}>
													III Edizione
												</p>
												<p className="text-white font-64">
													<strong>Mostra mercato delle meraviglie</strong>
												</p>
												<p
													className="text-white font-24"
													style={{
														fontFamily: "Open Sans",
														fontWeight: "600",
													}}>
													21-22 settembre 2024, Horti del Collegio Borromeo,
													Pavia
												</p>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li className="item">
								<div
									style={{
										height: "100vh",
										backgroundImage: `url(${bg4})`,
										backgroundSize: "cover",
										backgroundPosition: "center",
									}}>
									<div
										className="overlay"
										style={{
											position: "absolute",
											top: 0,
											left: 0,
											width: "100%",
											height: "100%",
											background:
												"linear-gradient(180deg, rgba(0,0,0,.52) 0%, rgba(0,0,0,.52) 60%)",
											zIndex: 0,
										}}></div>
									<div
										className="relative px-6"
										style={{
											height: "80vh",
											display: "table",
											tableLayout: "fixed",
											width: "100%",
										}}>
										<div
											style={{
												height: "80vh",
												display: "table-cell",
												width: "100%",
												verticalAlign: "middle",
												textAlign: "center",
											}}>
											<div className="col-md-12 text-center p-40">
												<p
													style={{
														fontFamily: "Open Sans",
														fontSize: "30px",
														color: "#fff",
													}}>
													III Edizione
												</p>
												<p className="text-white font-64">
													<strong>Sapere e saper fare</strong>
												</p>
												<p className="text-white font-24">
													21-22 settembre 2024, Horti del Collegio Borromeo,
													Pavia
												</p>
											</div>
										</div>
									</div>
								</div>
							</li>
						</OwlCarousel>
					</ul>
				</div>
			</div>
		</>
	);
};

export default Hero;
