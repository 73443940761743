import React from "react";
import { Link } from "react-router-dom";

import "./about.css";

import { about } from "site-structure";

import left from "images/flower_bg.png";
import right from "images/flower_bg.png";

const About = ({ description, pdf_url }) => {
	return (
		<section style={{ position: "relative", overflow: "visible" }}>
			<div
				className="side-background left"
				style={{
					backgroundImage: `url(${left})`,
					backgroundPosition: "center right",
				}}></div>
			<div className="container pt-0 pt-20 pt-sm-0">
				<div className="section-title">
					<div className="col-md-6 col-md-offset-3 mb-20">
						<div className="text-center">
							<h2 className="title">La manifestazione</h2>
						</div>
					</div>
				</div>

				<div
					className="col-md-12 centered-content api-text text-center"
					style={{ background: "#fff" }}>
					<p
						className="mb-0"
						dangerouslySetInnerHTML={{ __html: description }}
						style={{ paddingTop: "15px", paddingBottom: "15px" }}></p>
					<Link
						className="btn btn-colored btn-theme-colored btn-lg text-uppercase smooth-scroll font-13 mt-30"
						to="/dettaglio-programma">
						Scopri di più
					</Link>
					&nbsp;&nbsp;
					<a
						href={pdf_url}
						className="btn btn-dark-light btn-lg text-uppercase smooth-scroll font-13 mt-30"
						target="_blank"
						rel="noreferrer">
						Scarica il programma
					</a>
				</div>
			</div>
			<div
				className="side-background right"
				style={{
					backgroundImage: `url(${right})`,
					backgroundSize: "cover",
					backgroundPosition: "center right",
				}}></div>
		</section>
	);
};

export default About;
