import React from "react";
import { Link } from "react-router-dom";

import card_bg from "images/cover-right.jpg";

const Counters = ({ expositors, conferences, labs }) => {
	const val0 = labs[Object.keys(labs)[0]].length;
	const val1 = labs[Object.keys(labs)[1]].length;
	const totVal = val0 + val1;

	return (
		<section
			// className="divider parallax layer-overlay"
			className="divider"
			// style={{
			// 	backgroundImage: `url(${card_bg})`,
			// 	backgroundSize: "cover",
			// 	backgroundPosition: "center",
			// }}
		>
			<div className="container pt-sm-0">
				<div class="row">
					<div class="col-md-4 mb-sm-20">
						<Link to="/conferenze">
							<div class="about-icon bg-light">
								<i class="icon pe-7s-micro font-32"></i>
								<h4 class="title">{conferences}</h4>
								<p>Conferenze</p>
								<br />
								<button class="btn btn-dark btn-theme-colored btn-l">
									Vai alle conferenze
								</button>
							</div>
						</Link>
					</div>
					<div class="col-md-4 mb-sm-20">
						<Link to="/laboratori">
							<div class="about-icon bg-light">
								<i class="icon pe-7s-display1 font-32"></i>
								<h4 class="title">{totVal}</h4>
								<p>Laboratori</p>
								<br />
								<button class="btn btn-dark btn-theme-colored btn-l">
									Vai ai laboratori
								</button>
							</div>
						</Link>
					</div>
					<div class="col-md-4 mb-sm-20">
						<Link to="/espositori">
							<div class="about-icon bg-light">
								<i class="icon pe-7s-photo-gallery font-32"></i>
								<h4 class="title">{expositors}</h4>
								<p>Espositori</p>
								<br />
								<button class="btn btn-dark btn-theme-colored btn-l">
									Vai agli espositori
								</button>
							</div>
						</Link>
					</div>
				</div>
				{/* <div className="row">
					<div className="col-xs-12 col-sm-4 col-md-4 mb-md-50">
						<div className="funfact border-light pt-15 pr-40 pb-15 pl-20">
							<Link to="/conferenze">
								<i className="pe-7s-users text-white mt-5 font-48 pull-left flip"></i>
								<h2 className="animate-number text-white mt-0 font-48 pull-right flip appeared">
									{conferences}
								</h2>
								<div className="clearfix"></div>
								<h4 className="text-uppercase text-right flip font-14 text-white">
									Conferenze
								</h4>
							</Link>
						</div>
					</div>
					<div className="col-xs-12 col-sm-4 col-md-4 mb-md-50">
						<div className="funfact border-light pt-15 pr-40 pb-15 pl-20">
							<Link to="/laboratori">
								<i className="pe-7s-global text-white mt-5 font-48 pull-left flip"></i>
								<h2 className="animate-number text-white mt-0 font-48 pull-right flip appeared">
									{totVal}
								</h2>
								<div className="clearfix"></div>
								<h4 className="text-uppercase text-right flip font-14 text-white">
									Laboratori
								</h4>
							</Link>
						</div>
					</div>
					<div className="col-xs-12 col-sm-4 col-md-4 mb-md-50">
						<div className="funfact border-light pt-15 pr-40 pb-15 pl-20">
							<Link to="/espositori">
								<i className="pe-7s-albums text-white mt-5 font-48 pull-left flip"></i>
								<h2 className="animate-number text-white mt-0 font-48 pull-right flip appeared">
									{expositors}
								</h2>
								<div className="clearfix"></div>
								<h4 className="text-uppercase text-right flip font-14 text-white">
									Espositori
								</h4>
							</Link>
						</div>
					</div>
				</div> */}
			</div>
		</section>
	);
};

export default Counters;
