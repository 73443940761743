import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";

import styles from "input.css";

import bg from "images/cover-right.jpg";

import { useParams } from "react-router-dom";

const Programma = () => {
	const [siteData, setSiteData] = useState(null);
	let { id } = useParams();

	useEffect(() => {
		const fetchData = async (section = null, event_id = null) => {
			let params = "";
			let url = "https://hortiaperti.com/api/?event_id=1&section=programs";

			if (event_id) {
				params += "?event_id=" + event_id;
			}

			if (section) {
				if (event_id) {
					params += "&section=" + section;
				} else {
					params += "?section=" + section;
				}
			}

			url += params;
			const options = {
				method: "GET",
				headers: {
					"X-API-KEY": "4c897063-63e3-4c5e-a734-ee68a03f7c3b",
				},
			};

			fetch(url, options)
				.then((response) => {
					if (!response.ok) {
						throw new Error("Network response was not ok " + response.statusText);
					}

					return response.json();
				})
				.then((data) => {
					setSiteData(data);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		};

		fetchData();
	}, []);

	if (siteData) {
		const program = siteData.programs.filter((oggetto) =>
			oggetto.name.includes(id)
		);

		return (
			<>
				<div id="wrapper">
					<Header />

					<div className="main-content">
						<section
							className="inner-header divider parallax layer-overlay overlay-dark-5"
							style={{
								backgroundImage: `url(${program[0].cover_url})`,
								backgroundPosition: "50%",
							}}>
							<div className="container pt-90 pb-30">
								<div className="section-content pt-100">
									<div className="row">
										<div className="col-md-12">
											<h3 className="title text-white">{id}</h3>
										</div>
										<div className="col-md-12">
											<ol className="breadcrumb text-center mt-10 white">
												<li>
													<Link className="text-white" to="/home">
														Home
													</Link>
												</li>
												<li className="active">Programma</li>
											</ol>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section className="bg-solid-color">
							<div className="container pb-80">
								<div className="row">
									<div className="col-md-6 mb-sm-30">
										<h3 className="sub-title font-28 text-gray-darkgray m-0 mt-0 mt-md-0">
											Programma
										</h3>
										<h2 className="title font-40 text-gray mt-0 mb-20">In dettaglio</h2>
										{program.description}
										<p
											className="mb-20 text-large"
											dangerouslySetInnerHTML={{
												__html: program[0].description,
											}}></p>
									</div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-12 mb-10">
												<img className="img-fullwidth" src="images/about/4.jpg" alt="" />
											</div>
											<div className="col-xs-6 col-md-6 pr-5">
												<img className="img-fullwidth" src="images/about/5.jpg" alt="" />
											</div>
											<div className="col-xs-6 col-md-6 pl-5">
												<img className="img-fullwidth" src="images/about/6.jpg" alt="" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>

					<Footer />
				</div>
			</>
		);
	}
};

export default Programma;
