import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";

import styles from "input.css";
import "./gallery.css";

import bg from "images/cover-right.jpg";
import left from "images/flower_bg.png";
import right from "images/flower_bg.png";

import { galleryPage } from "site-structure";

const Gallery = () => {
	const [siteData, setSiteData] = useState(null);

	const renderGallery = () =>
		siteData.gallery.map((image) => {
			return (
				<>
					<div
						className="col-xs-12 col-sm-6 col-md-3"
						style={{ marginBottom: "20px" }}>
						<div className="team-member">
							<div className="team-thumb">
								<a href={image} rel="noreferrer" target="_blank">
									<div
										className="img-fullwidth"
										style={{
											backgroundImage: `url(${image})`,
											height: 160,
											backgroundSize: "cover",
											backgroundPosition: "50% 50%",
											backgroundRepeat: "no-repeat",
											borderRadius: "5px",
										}}
									/>
								</a>
							</div>
						</div>
					</div>
				</>
			);

			return null;
		});

	useEffect(() => {
		const fetchData = async (section = null, event_id = null) => {
			let params = "";
			let url = "https://hortiaperti.com/api/?event_id=1&section=gallery";

			if (event_id) {
				params += "?event_id=" + event_id;
			}

			if (section) {
				if (event_id) {
					params += "&section=" + section;
				} else {
					params += "?section=" + section;
				}
			}

			url += params;
			const options = {
				method: "GET",
				headers: {
					"X-API-KEY": "4c897063-63e3-4c5e-a734-ee68a03f7c3b",
				},
			};

			fetch(url, options)
				.then((response) => {
					if (!response.ok) {
						throw new Error(
							"Network response was not ok " + response.statusText
						);
					}

					return response.json();
				})
				.then((data) => {
					setSiteData(data);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		};

		fetchData();
	}, []);

	if (siteData) {
		return (
			<>
				<div id="wrapper">
					<Header />

					<div className="main-content">
						<section
							className="inner-header divider parallax layer-overlay overlay-dark-5"
							style={{
								backgroundImage: `url(${bg})`,
								backgroundPosition: "50%",
							}}>
							<div className="container pt-30 pb-40">
								<div className="section-content pt-100">
									<div className="row">
										<div className="col-md-12">
											<h3 className="title text-white">
												{galleryPage.pageTitle}
											</h3>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section>
							<div
								className="side-background-gallery left"
								style={{
									backgroundImage: `url(${left})`,
									backgroundPosition: "center right",
								}}></div>
							<div className="container pt-40">
								<div className="row multi-row-clearfix">
									<div className="blog-posts">{renderGallery()}</div>
								</div>
							</div>
							<div
								className="side-background-gallery right"
								style={{
									backgroundImage: `url(${right})`,
									backgroundPosition: "center right",
								}}></div>
						</section>
					</div>

					<Footer />
				</div>
			</>
		);
	}
};

export default Gallery;
