import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Speaker = ({ conferences }) => {
	const [siteData, setSiteData] = useState(null);

	const renderConf1 = () =>
		siteData.conferences[Object.keys(siteData.conferences)[0]].map((conf) => {
			return (
				<>
					<div className="col-xs-12 col-sm-6 col-md-3 mb-30">
						<Link
							key={conf.title + "_link"}
							to={`/dettaglio-conferenza/${conf.title.replace(
								/\?/g,
								"--"
							)}/confData/${Object.keys(siteData.conferences)[0].toString()}`}>
							<div className="team-member clearfix">
								<div className="team-thumb">
									<div
										alt=""
										src={conf.cover_url}
										className="img-fullwidth"
										style={{
											backgroundImage: `url(${conf.cover_url})`,
											height: 320,
											backgroundSize: "cover",
											backgroundPosition: "center",
											borderRadius: "5px",
										}}></div>
								</div>
								<div className="overlay">
									<div className="content text-center">
										<h4 className="author mb-0">{conf.subtitle}</h4>
										<h6 class="title text-gray font-14 mt-5 mb-15">
											{conf.title}
										</h6>
									</div>
								</div>
							</div>
						</Link>
					</div>
				</>
			);
		});

	const renderConf2 = () =>
		siteData.conferences[Object.keys(siteData.conferences)[1]].map((conf) => {
			return (
				<>
					<div className="col-xs-12 col-sm-6 col-md-3 mb-30">
						<Link
							key={conf.title + "_link"}
							to={`/dettaglio-conferenza/${conf.title.replace(
								/\?/g,
								"--"
							)}/confData/${Object.keys(siteData.conferences)[1].toString()}`}>
							<div className="team-member clearfix">
								<div className="team-thumb">
									<div
										alt=""
										src={conf.cover_url}
										className="img-fullwidth"
										style={{
											backgroundImage: `url(${conf.cover_url})`,
											height: 320,
											backgroundSize: "cover",
											backgroundPosition: "center",
											borderRadius: "5px",
										}}></div>
								</div>
								<div className="overlay">
									<div className="content text-center">
										<h4 className="author mb-0">{conf.subtitle}</h4>
										<h6 class="title text-gray font-14 mt-5 mb-15">
											{conf.title}
										</h6>
									</div>
								</div>
							</div>
						</Link>
					</div>
				</>
			);

			return null;
		});

	useEffect(() => {
		const fetchData = async (section = null, event_id = null) => {
			let params = "";
			let url = "https://hortiaperti.com/api/?event_id=1&section=conferences";

			if (event_id) {
				params += "?event_id=" + event_id;
			}

			if (section) {
				if (event_id) {
					params += "&section=" + section;
				} else {
					params += "?section=" + section;
				}
			}

			url += params;
			const options = {
				method: "GET",
				headers: {
					"X-API-KEY": "4c897063-63e3-4c5e-a734-ee68a03f7c3b",
				},
			};

			fetch(url, options)
				.then((response) => {
					if (!response.ok) {
						throw new Error(
							"Network response was not ok " + response.statusText
						);
					}

					return response.json();
				})
				.then((data) => {
					setSiteData(data);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		};

		fetchData();
	}, []);

	if (siteData)
		return (
			<section>
				<div className="divider parallax layer-overlay overlay-darkblue">
					<div className="container pt-50 pb-50">
						<div className="section-title">
							<div className="row">
								<div className="col-md-6 col-md-offset-3 text-center">
									<h2 className="title text-white mb-0">Relatori</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="bg-light">
					<div className="container-fluid pt-30 pb-0">
						<div className="section-content">
							<div className="row multi-row-clearfix">
								{renderConf1()}
								{renderConf2()}
							</div>
						</div>
					</div>
				</div>
			</section>
		);
};

export default Speaker;
