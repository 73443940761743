import React, { useState, useEffect } from "react";

import card_bg from "images/cover-right.jpg";

import bg from "images/bg-1.jpg";

const Countdown = ({ location, expositors }) => {
	const [timeLeft, setTimeLeft] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});

	var countdownDate = new Date("2024-09-21 10:00").getTime(); // Imposta la data e l'ora del tuo evento

	useEffect(() => {
		// Funzione per aggiornare il countdown
		const updateCountdown = () => {
			const now = new Date().getTime();
			const distance = countdownDate - now;

			if (distance < 0) {
				// Se il countdown è terminato
				setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
				clearInterval(interval);
			} else {
				// Calcolo del tempo rimanente
				const days = Math.floor(distance / (1000 * 60 * 60 * 24));
				const hours = Math.floor(
					(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				);
				const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
				const seconds = Math.floor((distance % (1000 * 60)) / 1000);
				setTimeLeft({ days, hours, minutes, seconds });
			}
		};

		// Avvio dell'intervallo
		let interval = setInterval(updateCountdown, 1000);

		// Pulizia dell'intervallo
		return () => clearInterval(interval);
	}, [countdownDate]);

	return (
		// <div className="container-fluid pt-0 pb-0 mb-0">
		// 	<div className="row">
		// 		<div className="divider layer-overlay overlay-lightest equal-height">
		// 			<div
		// 				className="col-md-12 col-lg-6 pr-0 pl-md-0 sm-height-auto bg-theme-colored"
		// 				style={{
		// 					// backgroundImage: `url(${card_bg})`,
		// 					backgroundSize: "cover",
		// 					backgroundPosition: "center",
		// 				}}>
		// 				<div id="clock" className="countdown-timer timer-box pt-90 pb-90">
		// 					<ul className="countdown-timer">
		// 						<li>
		// 							{timeLeft.days} <span>Giorni</span>
		// 						</li>
		// 						<li>
		// 							{timeLeft.hours} <span>Ore</span>
		// 						</li>
		// 						<li>
		// 							{timeLeft.minutes} <span>Minuti</span>
		// 						</li>
		// 						<li>
		// 							{timeLeft.seconds} <span>Secondi</span>
		// 						</li>
		// 					</ul>
		// 				</div>
		// 			</div>
		// 			<div
		// 				className="col-md-12 col-lg-6 md-text-center sm-text-center"
		// 				style={{ backgroundColor: "#fff" }}>
		// 				<div className="p-50 pl-md-0 pr-0">
		// 					<h2 className="text-black-444">Horti Aperti in sintesi</h2>
		// 					<ul className="list-inline xs-list-inline-none mt-50">
		// 						<li>
		// 							<h4 className="text-gray">
		// 								<i className="fa fa-map-marker text-theme-colored"></i>{" "}
		// 								Location
		// 							</h4>
		// 							<h6 className="text-dark font-16">
		// 								{location ? location : ""}
		// 							</h6>
		// 						</li>
		// 						<li className="ml-30">
		// 							<h4 className="text-gray">
		// 								<i className="fa fa-ticket text-theme-colored"></i>{" "}
		// 								Indirizzo
		// 							</h4>
		// 							<h6 className="text-dark font-16">
		// 								Viale Lungo Ticino Sforza, 46
		// 							</h6>
		// 						</li>
		// 						<li className="ml-30">
		// 							<h4 className="text-gray">
		// 								<i className="fa fa-microphone text-theme-colored"></i>{" "}
		// 								Orario
		// 							</h4>
		// 							<h6 className="text-dark font-16">Dalle 10 alle 19</h6>
		// 						</li>
		// 					</ul>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>
		<section style={{ position: "relative", zIndex: 1000 }}>
			<div className="container pt-0">
				<div className="row mb-0">
					<div className="col-md-12">
						<div
							className="divider layer-overlay overlay-deep"
							style={{
								// backgroundImage: `url(${card_bg})`,
								background: "#fafafa",
								backgroundSize: "cover",
								backgroundPosition: "center",
								marginTop: -160 + "px",
							}}>
							<div className="col-md-12 col-lg-8 p-50 pr-0 md-text-center sm-text-center pl-sm-0">
								<h2 className="text-black-444">Horti Aperti in sintesi</h2>
								<ul className="list-inline xs-list-inline-none mt-50">
									<li>
										<h4 className="text-gray-light">
											<i className="fa fa-map-marker text-theme-colored"></i>{" "}
											Location
										</h4>
										<h6 className="text-gray">
											{location ? location : ""}, Horti del Collegio Borromeo
										</h6>
									</li>
									<li className="ml-30">
										<h4 className="text-gray-light">
											<i className="fa fa-home text-theme-colored"></i>{" "}
											Indirizzo
										</h4>
										<h6 className="text-gray">Viale Lungo Ticino Sforza, 46</h6>
									</li>
									<li className="ml-30">
										<h4 className="text-gray-light">
											<i className="fa fa-clock-o text-theme-colored"></i>{" "}
											Orario
										</h4>
										<h6 className="text-gray">Dalle 10 alle 19</h6>
									</li>
								</ul>
							</div>
							<div className="col-md-12 col-lg-4 pr-0 pl-md-0">
								<div
									id="clock"
									className="countdown-timer timer-box bg-theme-colored">
									<ul className="countdown-timer">
										<li>
											{timeLeft.days} <span>Giorni</span>
										</li>
										<li>
											{timeLeft.hours} <span>Ore</span>
										</li>
										<li>
											{timeLeft.minutes} <span>Minuti</span>
										</li>
										<li>
											{timeLeft.seconds} <span>Secondi</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Countdown;
