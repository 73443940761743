import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";

import styles from "input.css";
import "./conferenze.css";

import { conferenzePage } from "site-structure";

import bg from "images/cover-right.jpg";
import left from "images/flower_bg.png";
import right from "images/flower_bg.png";

const Conferenze = () => {
	const [siteData, setSiteData] = useState(null);
	const [visibleDiv, setVisibleDiv] = useState("conf-1");
	const [activeDiv, setActiveDiv] = useState(1);

	const renderConf1 = () =>
		siteData.conferences[Object.keys(siteData.conferences)[0]].map((conf) => {
			return (
				<>
					<div className="cd-timeline-block" key={conf.title + "_key"}>
						<div className="cd-timeline-img cd-movie">
							{" "}
							<i className="fa fa-user"></i>{" "}
						</div>

						<div className="cd-timeline-content">
							<div className="row equal-height">
								<div className="col-sm-8 pr-0 sm-height-auto">
									<div className="cd-content-left p-15">
										<Link
											key={conf.title + "_link"}
											to={`/dettaglio-conferenza/${conf.title.replace(
												/\?/g,
												"--"
											)}/confData/${Object.keys(
												siteData.conferences
											)[0].toString()}`}>
											<h5
												className="title"
												dangerouslySetInnerHTML={{
													__html: conf.title,
												}}
												style={{ fontFamily: "Rothorn DC", marginTop: 0 }}></h5>
										</Link>
										<div className="cd-speaker clearfix">
											<div
												style={{
													width: 70,
													height: 70,
													backgroundImage: `url(${conf.cover_url})`,
													backgroundPosition: "center",
													backgroundSize: "cover",
												}}
												width="70"
												height="70"
												className="pull-left flip img-circle mr-15"
											/>
											<h6 className="pt-0 mb-0">{conf.subtitle}</h6>
											<span className="font-12">
												{conf.max_num_participants.length > 0
													? `Capienza: ${conf.max_num_participants}`
													: ""}
											</span>
											<br />
											<br />
											<p
												dangerouslySetInnerHTML={{
													__html: conf.preview_des,
												}}></p>
										</div>
									</div>
								</div>
								<div className="col-sm-4 pl-0 pl-sm-15 sm-height-auto">
									<div className="cd-content-right p-15">
										<ul className="cd-timeline-meta mt-sm-0">
											<li style={{ color: "#111" }}>
												<i className="fa fa-clock-o"></i> {conf.hour}
											</li>
											<li style={{ color: "#111" }}>
												<i className="fa fa-calendar"></i> {conf.day}
											</li>
										</ul>
										<a
											target="_blank"
											rel="noreferrer"
											href={conf.link_to_buy}
											className="btn btn-dark btn-theme-colored btn-l">
											{conf.reservation === "1" ? "Acquista" : "Prenota"}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* <tr>
						<td>{lab.hour}</td>
						<td>
							<Link
								key={lab.title + "_link"}
								to={`/dettaglio-laboratorio/${lab.title}/labData/${Object.keys(
									siteData.labs
								)[0].toString()}`}>
								<h5
									className="title"
									dangerouslySetInnerHTML={{
										__html: lab.title,
									}}></h5>
							</Link>

							<h6 className="name">{lab.subtitle}</h6>
							<p className="">
								<br />
								<p
									dangerouslySetInnerHTML={{
										__html: lab.preview_des,
									}}></p>

								{lab.max_num_participants.length > 0
									? `Capienza: ${lab.max_num_participants}`
									: ""}
							</p>
						</td>
						<td>{lab.place}</td>
						<td>
							<a
								target="_blank"
								rel="noreferrer"
								href={lab.link_to_buy}
								class="btn btn-dark btn-theme-colored btn-l">
								{lab.reservation === "1" ? "Acquista" : "Prenota"}
							</a>
						</td>
					</tr> */}
				</>
			);

			return null;
		});

	const renderConf2 = () =>
		siteData.conferences[Object.keys(siteData.conferences)[1]].map((conf) => {
			return (
				<>
					<div className="cd-timeline-block" key={conf.title + "_key"}>
						<div className="cd-timeline-img cd-movie">
							{" "}
							<i className="fa fa-user"></i>{" "}
						</div>
						<div className="cd-timeline-content">
							<div className="row equal-height">
								<div className="col-sm-8 pr-0 sm-height-auto">
									<div className="cd-content-left p-15">
										<Link
											key={conf.title + "_link"}
											to={`/dettaglio-conferenza/${conf.title.replace(
												/\?/g,
												"--"
											)}/confData/${Object.keys(
												siteData.conferences
											)[1].toString()}`}>
											<h5
												className="title"
												dangerouslySetInnerHTML={{
													__html: conf.title,
												}}
												style={{
													fontFamily: "Rothorn DC",
													marginTop: 0,
												}}></h5>
										</Link>
										<div className="cd-speaker clearfix">
											<div
												style={{
													width: 70,
													height: 70,
													backgroundImage: `url(${conf.cover_url})`,
													backgroundPosition: "center",
													backgroundSize: "cover",
												}}
												width="70"
												height="70"
												className="pull-left flip img-circle mr-15"
											/>
											<h6 className="pt-0 mb-0">{conf.subtitle}</h6>
											<span className="font-12">
												{conf.max_num_participants.length > 0
													? `Capienza: ${conf.max_num_participants}`
													: ""}
											</span>
											<br />
											<br />
											<p
												dangerouslySetInnerHTML={{
													__html: conf.preview_des,
												}}></p>
										</div>
									</div>
								</div>
								<div className="col-sm-4 pl-0 pl-sm-15 sm-height-auto">
									<div className="cd-content-right p-15">
										<ul className="cd-timeline-meta mt-sm-0">
											<li style={{ color: "#111" }}>
												<i className="fa fa-clock-o"></i> {conf.hour}
											</li>
											<li style={{ color: "#111" }}>
												<i className="fa fa-calendar"></i> {conf.day}
											</li>
										</ul>
										<a
											target="_blank"
											rel="noreferrer"
											href={conf.link_to_buy}
											className="btn btn-dark btn-theme-colored btn-l">
											{conf.reservation === "1" ? "Acquista" : "Prenota"}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <tr>
						<td>{lab.hour}</td>
						<td>
							<Link
								key={lab.title + "_link"}
								to={`/dettaglio-laboratorio/${lab.title}/labData/${Object.keys(
									siteData.labs
								)[1].toString()}`}>
								<h5
									className="title"
									dangerouslySetInnerHTML={{
										__html: lab.title,
									}}></h5>
							</Link>

							<h6 className="name">{lab.subtitle}</h6>
							<p className="">
								<br />
								<p
									dangerouslySetInnerHTML={{
										__html: lab.preview_des,
									}}></p>

								{lab.max_num_participants.length > 0
									? `Capienza: ${lab.max_num_participants}`
									: ""}
							</p>
						</td>
						<td>{lab.place}</td>
						<td>
							<a
								target="_blank"
								rel="noreferrer"
								href={lab.link_to_buy}
								class="btn btn-dark btn-theme-colored btn-l">
								{lab.reservation === "1" ? "Acquista" : "Prenota"}
							</a>
						</td>
					</tr> */}
				</>
			);

			return null;
		});

	useEffect(() => {
		const fetchData = async (section = null, event_id = null) => {
			let params = "";
			let url = "https://hortiaperti.com/api/?event_id=1&section=conferences";

			if (event_id) {
				params += "?event_id=" + event_id;
			}

			if (section) {
				if (event_id) {
					params += "&section=" + section;
				} else {
					params += "?section=" + section;
				}
			}

			url += params;
			const options = {
				method: "GET",
				headers: {
					"X-API-KEY": "4c897063-63e3-4c5e-a734-ee68a03f7c3b",
				},
			};

			fetch(url, options)
				.then((response) => {
					if (!response.ok) {
						throw new Error(
							"Network response was not ok " + response.statusText
						);
					}

					return response.json();
				})
				.then((data) => {
					setSiteData(data);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		};

		fetchData();
	}, []);

	if (siteData) {
		return (
			<>
				<div id="wrapper">
					<Header />

					<div className="main-content">
						<section
							className="inner-header divider parallax layer-overlay overlay-dark-5"
							style={{
								backgroundImage: `url(${bg})`,
								backgroundPosition: "50%",
							}}>
							<div className="container pt-40 pb-30">
								<div className="section-content pt-100">
									<div className="row">
										<div className="col-md-12">
											<h3 className="title text-white">
												{conferenzePage.pageTitle}
											</h3>
										</div>
										<div className="col-md-12">
											<ol className="breadcrumb text-center mt-10 white">
												<li>
													<Link className="text-white" to="/home">
														Home
													</Link>
												</li>
												<li className="active">Conferenze</li>
											</ol>
										</div>
									</div>
								</div>
							</div>
						</section>

						{/* <section id="schedule">
							<div
								className="layer-overlay overlay-light"
								data-bg-img="images/bg/bg5.jpg"
								sx='background-image: url("images/bg/bg5.jpg");'>
								<div className="container pt-80 pb-50">
									<div className="section-content">
										<div className="row">
											<div className="col-md-12">
												<table className="table table-striped table-schedule">
													<thead>
														<tr>
															<th sx="width: 20%;">Quando</th>
															<th sx="width: 45%;">Evento</th>
															<th sx="width: 25%;">Luogo</th>
															<th sx="width: 10%;"></th>
														</tr>
													</thead>
													<tbody>{renderConferences()}</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section> */}

						<section id="schedule">
							<div
								className="side-background-conferenze left"
								style={{
									backgroundImage: `url(${left})`,
									backgroundPosition: "center right",
								}}></div>
							<div className="">
								<div className="container pt-40 pb-80">
									<div className="section-content">
										<div className="row">
											<div className="col-md-12">
												<div className="schedule-tab">
													<ul className="nav nav-tabs">
														<li className={activeDiv === 1 ? "active" : ""}>
															<a
																style={{ cursor: "pointer" }}
																onClick={() => {
																	setVisibleDiv("conf-1");
																	setActiveDiv(1);
																}}>
																Giorno 1{" "}
																<span>
																	{Object.keys(
																		siteData.conferences
																	)[0].toString()}
																</span>
															</a>
														</li>
														<li className={activeDiv === 2 ? "active" : ""}>
															<a
																style={{ cursor: "pointer" }}
																onClick={() => {
																	setVisibleDiv("conf-2");
																	setActiveDiv(2);
																}}>
																Giorno 2{" "}
																<span>
																	{Object.keys(
																		siteData.conferences
																	)[1].toString()}
																</span>
															</a>
														</li>
													</ul>

													<div className="tab-content">
														{visibleDiv === "conf-1" && (
															<div
																className="tab-pane fade in active"
																id="conf-1">
																<div className="cd-timeline cd-container">
																	{renderConf1()}
																</div>
															</div>
														)}
														{visibleDiv === "conf-2" && (
															<div
																className="tab-pane fade in active"
																id="conf-2">
																<div className="cd-timeline cd-container">
																	{renderConf2()}
																</div>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								className="side-background-conferenze right"
								style={{
									backgroundImage: `url(${left})`,
									backgroundPosition: "center right",
								}}></div>
						</section>
					</div>

					<Footer />
				</div>
			</>
		);
	}
};

export default Conferenze;
