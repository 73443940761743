import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";

import styles from "input.css";

import bg from "images/cover-right.jpg";
import img from "images/parcheggio-sorvegliato.gif";

import { areaStampaPage } from "site-structure";

const AreaStampa = () => {
	const [siteData, setSiteData] = useState(null);

	const renderPress = () =>
		siteData.press.map((article) => {
			return (
				<>
					<div className="mb-50">
						<h3>{article.title}</h3>
						<hr />
						<p className="mb-20">
							<a
								target="_blank"
								rel="noreferrer"
								href={article.pdf_url}
								class="btn btn-dark btn-theme-colored btn-l">
								Leggi il comunicato
							</a>
						</p>
					</div>
				</>
			);
		});

	useEffect(() => {
		const fetchData = async (section = null, event_id = null) => {
			let params = "";
			let url = "https://hortiaperti.com/api/?event_id=1&section=press";

			if (event_id) {
				params += "?event_id=" + event_id;
			}

			if (section) {
				if (event_id) {
					params += "&section=" + section;
				} else {
					params += "?section=" + section;
				}
			}

			url += params;
			const options = {
				method: "GET",
				headers: {
					"X-API-KEY": "4c897063-63e3-4c5e-a734-ee68a03f7c3b",
				},
			};

			fetch(url, options)
				.then((response) => {
					if (!response.ok) {
						throw new Error(
							"Network response was not ok " + response.statusText
						);
					}

					return response.json();
				})
				.then((data) => {
					setSiteData(data);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		};

		fetchData();
	}, []);

	if (siteData) {
		return (
			<>
				<div id="wrapper">
					<Header />

					<div className="main-content">
						<section
							className="inner-header divider parallax layer-overlay overlay-dark-5"
							style={{
								backgroundImage: `url(${bg})`,
								backgroundPosition: "50%",
							}}>
							<div className="container pt-90 pb-30">
								<div className="section-content pt-100">
									<div className="row">
										<div className="col-md-12">
											<h3 className="title text-white">
												{areaStampaPage.pageTitle}
											</h3>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section>
							<div className="container">
								<div className="row">
									<div className="col-md-10 col-md-push-1">{renderPress()}</div>
								</div>
							</div>
						</section>
					</div>

					<Footer />
				</div>
			</>
		);
	}
};

export default AreaStampa;
