import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";

import styles from "input.css";
import "./arrivare.css";

import bg from "images/cover-right.jpg";
import left from "images/flower_bg.png";
import right from "images/flower_bg.png";
import img from "images/parcheggio-sorvegliato.gif";

import { arrivarePage } from "site-structure";

const Arrivare = () => {
	return (
		<>
			<div id="wrapper">
				<Header />

				<div className="main-content">
					<section
						className="inner-header divider parallax layer-overlay overlay-dark-5"
						style={{
							backgroundImage: `url(${bg})`,
							backgroundPosition: "50%",
						}}>
						<div className="container pt-30 pb-40">
							<div className="section-content pt-100">
								<div className="row">
									<div className="col-md-12">
										<h3 className="title text-white">
											{arrivarePage.pageTitle}
										</h3>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>

				<section>
					<div
						className="side-background-arrivare left"
						style={{
							backgroundImage: `url(${left})`,
							backgroundPosition: "center right",
						}}></div>
					<div className="container pt-10 pb-50">
						<div className="section-content">
							<div className="row multi-row-clearfix">
								<div className="col-xs-12 col-sm-6 col-md-4">
									<div class="esc-heading heading-line-bottom lr-line left-heading">
										<h5>Parcheggio Palazzo Esposizioni</h5>
									</div>
									<iframe
										class="gmap"
										src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5624.910496779354!2d9.161935456612422!3d45.17787999670678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4787263dd1193e6b%3A0xaa12acc04d26dbb7!2sPalazzo%20delle%20Esposizioni!5e0!3m2!1sit!2sit!4v1715873046788!5m2!1sit!2sit"
										frameborder="0"
										scrolling="no"
										marginheight="0"
										marginwidth="0"
										height="400px"
										title="1"></iframe>
								</div>
								<div className="col-xs-12 col-sm-6 col-md-4">
									<div class="esc-heading heading-line-bottom lr-line left-heading">
										<h5>Horti del Collegio Borromeo</h5>
									</div>
									<iframe
										class="gmap"
										src="https://maps.google.com/maps?q=Lungo%20Ticino%20Sforza%2C%2046%2C%2027100%20Pavia%20PV%2C%20Italy&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
										frameborder="0"
										scrolling="no"
										marginheight="0"
										marginwidth="0"
										height="400px"
										title="2"></iframe>
								</div>
								<div className="col-xs-12 col-sm-6 col-md-4">
									<br />
									<br />
									<img src={img} />
								</div>
							</div>
						</div>
					</div>
					<div
						className="side-background-arrivare right"
						style={{
							backgroundImage: `url(${right})`,
							backgroundPosition: "center right",
						}}></div>
				</section>

				<Footer />
			</div>
		</>
	);
};

export default Arrivare;
