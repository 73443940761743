import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import { routes } from "routes.js";

import "./header.css"; // Assicurati di includere il CSS

import logo from "images/logo-small-white.png";

let location;

const Header = () => {
	const [scroll, setScroll] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	location = useLocation();

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

	return (
		<>
			<header className="header">
				<div
					className={
						scroll
							? "header-nav navbar-fixed-top navbar-dark navbar-transparent navbar-sticky-animated"
							: "header-nav navbar-fixed-top navbar-dark navbar-transparent navbar-sticky-animated animated-active"
					}>
					<div className="header-nav-wrapper" style={{ height: 70 }}>
						<div className="container">
							<nav className="navbar2 menuzord green" style={{ height: 70 }}>
								<Link
									className="menuzord-brand pull-left flip"
									to="/home"
									style={{ margin: 0 }}>
									<img src={logo} alt="" style={{ width: 180 }} />
								</Link>
								<div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
									<i className={isOpen ? "fa fa-times" : "fa fa-bars"}></i>
								</div>
								<ul
									className={
										isOpen
											? "nav-links open menuzord-menu"
											: "nav-links menuzord-menu"
									}>
									<li>
										<Link to="/conferenze">Conferenze</Link>
									</li>
									<li>
										<Link to="/laboratori">Laboratori</Link>
									</li>
									<li>
										<Link to="/espositori">Espositori</Link>
									</li>
									<li>
										<Link to="/gallery">Gallery</Link>
									</li>
									<li>
										<Link to="/come-arrivare">Come arrivare</Link>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
